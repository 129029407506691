import React from 'react'
import queryString from 'query-string'
import { useLocation } from '@reach/router'

import { Default, Inui, Conshelly } from './agreementsData'
import { AgreementContainer } from './Agreements.style'
import { APP_IDS } from '../../constants'

function Agreements () {
  const location = useLocation()
  const _renderAgreements = () => {
    const query = queryString.parse(location.search)
    if (query?.appid) {
      switch (query.appid) {
        case APP_IDS.INUI:
          return <Inui />
        case APP_IDS.CONSHELLY:
          return <Conshelly />
        default:
          return <Default />
      }
    }
    return <Default />
  }
  return (
    <AgreementContainer>
      { _renderAgreements() }
    </AgreementContainer>
  )
}

Agreements.propTypes = {

}

export default Agreements
